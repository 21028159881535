<template>
  <div class="row m-0 abs">
    <div class="col-12 p-0 card_item">
      <div class="row mx-0 h-100 justify-content-center" v-if="cart.length > 0">
        <div class="col-12 p-3 text-center card_heading">
          Cart Total - {{ currency }} {{ total }}
        </div>
        <div class="col-12 p-0 border-top">
          <div class="row m-0 justify-content-center">
            <div class="col-12 p-0">
              <CartItemMenu v-for="item in cart" :key="item" :item="item" />
            </div>
          </div>
        </div>
        <div class="col-12 p-0 align-self-end">
          <div class="row m-0 background-green py-3 justify-content-center">
            <div class="col-6 pe-1">
              <Button color="green_light" width="100" btnText="Edit Cart" icon="arrow" @buttonClicked="editCart" class="px-2">
                <IconEdit size="size16" color="white" />
              </Button>
            </div>
            <div class="col-6 ps-1">
              <Button color="red" width="100" btnText="Checkout" icon="arrow" @buttonClicked="goCheckout" class="px-2">
                <IconArrowForward color="white" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mx-0 h-100 justify-content-center" v-else>
        <div class="col-12 p-3 text-center card_heading">
          No items in your cart
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    CartItemMenu: defineAsyncComponent(() => import('../components/CartItemMenu.vue')),
    IconArrowForward: defineAsyncComponent(() => import('../components/icons/IconArrowForward.vue')),
    IconEdit: defineAsyncComponent(() => import('../components/icons/IconEdit.vue'))
  },
  name: 'Cart Popup',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'cart'
    ]),
    currency () {
      if (this.cart.length > 0) {
        return this.cart[0].currency || ''
      }
      return process.env.VUE_APP_CURRENCY_SYMBOL
    },
    total () {
      let ret = 0
      this.cart.forEach(item => {
        ret += Number(item.amount)
      })
      return parseFloat(Math.round(ret * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
  },
  methods: {
    editCart () {
      this.$router.push('/checkout/1')
    },
    goCheckout () {
      this.$router.push('/checkout/0')
    }
  }
}
</script>

<style scoped>
.abs {
  position: absolute;
  width: 600%;
  left: -250%;
  max-height: 50vh;
  overflow-y: auto;
  z-index: 2;
}
.card_item {
  background-color: #fff;
  border-radius: 13px;
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
}
.card_title {
  font-family: "Quicksand", Sans-serif;
  font-size: 0.8rem;
  font-weight: bold;
  color: #000;
}
.card_heading {
  font-family: "Quicksand", Sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--green-color);
}
.background-green {
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}
</style>
